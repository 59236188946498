import { useClientConfig } from './ClientConfig'
import { initializeApp, getApp } from 'firebase/app'

/** @returns {import('firebase/app').FirebaseApp} */
export function useFirebaseApp(name) {
  const config = useClientConfig()

  const [firebaseApp, setFirebaseApp] = React.useState(null)
  React.useEffect(
    () => {
      let firebaseApp
      try {
        firebaseApp = getApp(name)
      } catch (err) {
        firebaseApp = initializeApp(config.firebase, name)
      }
      setFirebaseApp(firebaseApp)

    },
    [name, config]
  )

  return firebaseApp
}
